import React from "react";
import GoogleMaps from "./googleMaps";
import call_icon from "../assets/phone-call-warm.png";
import email_icon from "../assets/email-warm.png";
import address_icon from "../assets/address-warm.png";
import hours_icon from "../assets/hours-warm.png";
import "./css/styles.css";

const ContactView = () => {
  return (
    <>
      <div className="divider"></div>

      <div className="contactView" id="contact">
        <div className="map-container">
          <GoogleMaps />
        </div>
        <div className="contact-info-container">
          <div className="top">
            <div className="call-container info-container">
              <div className="img-container">
                <img src={call_icon} alt="call-us-icon" />
              </div>
              <div className="contactInfo">
                <h4>Call us</h4>
                <h6>907-435-0697</h6>
              </div>
            </div>
            <div className="email-container info-container">
              <div className="img-container">
                <img src={email_icon} alt="email-icon" />
              </div>
              <div className="contactInfo">
                <h4>Email</h4>
                <h6>ChefChris808907@gmail.com</h6>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="address-container info-container">
              <div className="img-container">
                <img src={address_icon} alt="" />
              </div>
              <div className="contactInfo">
                <h4>Address</h4>
                <h6>204 Ocean Dr. Loop, Homer, Alaska 99603</h6>
              </div>
            </div>
            <div className="hours-container info-container">
              <div className="img-container">
                <img src={hours_icon} alt="hoursIcon" />
              </div>
              <div className="contactInfo">
                <h4>Hours</h4>
                <h6>
                  4-9pm Tues-Thurs
                  <br /> 4-10:30pm Friday & Saturday <br />
                  Closed Sun & Mon
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="plugIn">
        Designed & created by claytonslade14@gmail.com <br></br>
        Located in Homer, Alaska
      </p>
    </>
  );
};

export default ContactView;
