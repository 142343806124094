import React from "react";
import hawaii_icon from "../assets/hawaii-warm.png";
import arrow_icon from "../assets/next-warm.png";
import alaska_icon from "../assets/alaska-cold.png";
import pineapple_icon from "../assets/pineapple-outline.png";
import palmTree_icon from "../assets/coconut-tree-trans.png";
import "./css/styles.css";

const MainView = () => {
  return (
    <>
      <header>
        <div className="top">
          <h2>Beluga Bar & Grill</h2>
          <div className="header-locations">
            <a href="#menu" className="menuLink">
              Menu
            </a>
            <div className="circle"></div>
            <a href="#contact" className="menuLink">
              Contact
            </a>
          </div>
        </div>
        <div className="bottom"></div>
      </header>
      <div className="mainView">
        <div className="leftView">
          <h1>
            A little taste of <br></br>
            <span className="aloha">aloha</span> in{" "}
            <span className="alaska">Alaska</span>
          </h1>
          <div className="state-icons">
            <img src={hawaii_icon} alt="hawaii-icon" />
            <img src={arrow_icon} alt="arrow" className="arrow" />
            <img src={alaska_icon} alt="alaska-icon" />
          </div>
          <a href="#menu" className="seeMenu">
            <div className="inside">
              <h6>See Menu</h6>
              <img src={pineapple_icon} alt="pineapple-icon" />
            </div>
          </a>
        </div>
        <div className="right-view">
          <img src={palmTree_icon} alt="palmTree-icon" />
          <div className="shadow"></div>
        </div>
      </div>
    </>
  );
};

export default MainView;
