import React from "react";

const GoogleMaps = () => {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2016.7266067125597!2d-151.52118498731997!3d59.637557186205335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x56c12d92d146cc63%3A0x99c9d86f7fc80fc7!2sBeluga%20Lake%20Lodge!5e0!3m2!1sen!2sus!4v1724357392405!5m2!1sen!2sus"
        width="100%"
        height="100%"
        allowFullScreen={true}
        title="googleMaps"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
};

export default GoogleMaps;
