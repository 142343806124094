import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, collectionData } from "../firebase.js";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./css/styles.css";

const variants = {
  open: { opacity: 1, transform: "scale(1)" },
  closed: { opacity: 0, transform: "scale(0)" },
};

const MenuView = () => {
  const [menuCataIndex, setMenuCataIndex] = useState(0);
  const [menu, setMenu] = useState();
  const [categorys, setCategorys] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const getMenu = async () => {
    const menuRef = doc(collectionData, "menu", "menuItems");
    const menuSnap = await getDoc(menuRef);

    if (menuSnap.exists()) {
      const data = menuSnap.data();

      setMenu(data);

      const categorys = data.sections.map((section) => section.name);
      setCategorys(categorys);
    }
  };

  useEffect(() => {
    getMenu();
  }, []);

  useEffect(() => {
    if (menu !== undefined && menu?.sections.length > 0) {
      setMenuItems(menu.sections[menuCataIndex].items);
    }
  }, [menuCataIndex, menu]);

  return (
    <>
      <a className="divider mainView-divider" href="#menu">
        {""}
      </a>

      <div className="menuView" id="menu">
        <MenuCategory
          categorys={categorys}
          setMenuCataIndex={setMenuCataIndex}
        />
        {menuItems.length > 0 ? <MenuItems items={menuItems} /> : null}
        <div className="legal-container">
          <p className="legal">
            ***Consuming raw or undercooked meats, poultry, seafood, shellfish,
            or eggs may increase your risk of food borne illness.
          </p>
          <br />
          <p className="legal">
            We use locally grown fresh vegetables when available. 20% gratuity
            on parties of 6 or more.
          </p>
        </div>
      </div>
    </>
  );
};

const MenuItems = ({ items }) => {
  return (
    <>
      <div className="menuItems-container">
        {items.length > 0
          ? items.map((item, index) => (
              <Item key={uuidv4()} index={index} itemInfo={item} />
            ))
          : null}
      </div>
    </>
  );
};

const Item = ({ itemInfo, index }) => {
  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    setAnimateIn(true);
  }, []);

  return (
    <>
      <motion.div
        animate={animateIn ? "open" : null}
        variants={variants}
        exit={"closed"}
        transition={{ delay: 0.25 * index }}
        className="item"
      >
        <div className="bg-opacity">
          <div className="top">
            <h4>{itemInfo.itemName}</h4>
            <div className="divider"></div>
          </div>
          <div className="middle">
            <p>{itemInfo.itemDescription}</p>
          </div>
          <div className="bottom">
            <div className="divider"></div>
            <p className="price">
              {itemInfo.itemPrice === "" ? null : "$"}
              <span className="price-text">{itemInfo.itemPrice}</span>
            </p>
          </div>
        </div>
      </motion.div>
    </>
  );
};

const MenuCategory = ({ categorys, setMenuCataIndex }) => {
  const [categoryName, setCategoryName] = useState("");

  const handleChange = (event) => {
    setCategoryName(event.target.value);
  };

  useEffect(() => {
    setCategoryName(categorys[0]);
  }, [categorys]);

  return (
    <>
      <Box className="category" sx={{ minWidth: 120 }}>
        <h2 className="info-tag">Click to view full menu</h2>
        <FormControl className="input-form">
          <InputLabel id="demo-simple-select-label" className="label">
            Menu Select
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={categoryName || ""}
            label="Menu Category"
            onChange={handleChange}
            className="selectInput"
          >
            {categorys.map((cata, index) => (
              <MenuItem
                key={uuidv4()}
                onClick={() => setMenuCataIndex(index)}
                value={cata}
              >
                {cata}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default MenuView;
