import React from "react";
import MainView from "./components/main-view";
import MenuView from "./components/menu-view";
import ContactView from "./components/contact-view";
import "./css/styles.css";

const Layout = () => {
  return (
    <>
      <div className="app-layout">
        <MainView />
        <MenuView />
        <ContactView />
      </div>
    </>
  );
};

export default Layout;
